// assets
/* eslint-disable prettier/prettier */
import { IconKey, IconLock } from '@tabler/icons';

// ==============================|| MultiSig MENU ITEMS ||============================== //

const vesting = {
    id: 'vesting',
    title: 'Vesting',
    type: 'group',
    children: [
        {
            id: 'create-vesting',
            title: 'Create Vesting',
            type: 'item',
            url: '/create-vesting',
            icon: IconLock,
            breadcrumbs: false
        },
        {
            id: 'track-vesting',
            title: 'Manage Vesting',
            type: 'item',
            url: '/manage-vesting',
            icon: IconKey,
            breadcrumbs: false
        },
        // {
        //     id: 'manage-wallet',
        //     title: 'Manage Wallet',
        //     type: 'item',
        //     url: '/multisig/manage-wallet',
        //     icon: AdminPanelSettingsIcon,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'manage-wallet-owners',
        //     title: 'Manage Wallet Owners',
        //     type: 'item',
        //     url: '/multisig/manage-wallet-owners',
        //     icon: AccountBalanceWalletIcon,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'manage-funds',
        //     title: 'Manage Funds',
        //     type: 'item',
        //     url: '/multisig/manage-funds',
        //     icon: AttachMoneyIcon,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'manage-tokens',
        //     title: 'Manage Tokens',
        //     type: 'item',
        //     url: '/multisig/manage-tokens',
        //     icon: TokenIcon,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'manage-transfers',
        //     title: 'Manage Transfers',
        //     type: 'item',
        //     url: '/multisig/manage-transfers',
        //     icon: SendToMobileIcon,
        //     breadcrumbs: false
        // }
              
    ]
};
export default vesting;
