import dashboard from './dashboard';
import pages from './pages';
import utilities from './utilities';
import other from './other';
import multiSig from './multiSig';
import governance from './governance';
import vesting from './vesting';
import staking from './staking';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, vesting, staking, governance, multiSig]
};

export default menuItems;
