/* eslint-disable prettier/prettier */
import { useState } from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    AlertTitle,
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography,
    useMediaQuery
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Google from 'assets/images/icons/social-google.svg';
import { useAuth } from 'Context/AuthContext';
import { useNavigate } from 'react-router';
import Alert from '@mui/material/Alert';
import { usePortalContext } from 'Context/PortalContext';
import CircularProgress from '@mui/material/CircularProgress';


// ============================|| FIREBASE - LOGIN ||============================ //

const FirebaseLogin = ({ ...others }) => {
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const customization = useSelector((state) => state.customization);
    const [checked, setChecked] = useState(true);
    const [formError, setFormError] = useState('');
    const [formInput, updateFormInput] = useState(
        { 
          email: '', 
          password: ''
      })

      const {connectWallet, authError} = useAuth();
      const { buySubscription, loadingState, subscriptionPurchased} = usePortalContext();
      const navigate = useNavigate();


    const connectUserWallet = async() =>{
        connectWallet();
        // if(connectedAccount){
        //     navigate('/dashboard/default');
        // }else{
        //     connectWallet();
        //     navigate('/dashboard/default');
        // }
    }

    return (
        <>
            <Grid container direction="column" justifyContent="center" spacing={2}>
                <Grid item xs={12}>
                    <AnimateButton>
                        
                        <Button
                                    
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    onClick={connectUserWallet}
                                >
                                   Connect Wallet
                                </Button>
                                
                                
                    </AnimateButton>
                    
                </Grid>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex'
                        }}
                    >
                        
                        {authError &&
                                (<div sx={{mt: 5}}>
                                    <Alert severity="error">
                                    <AlertTitle>Authorization Error</AlertTitle>
                                    Oops, It seems you don't have access to this portal, please purchase the NFT and try again later!!!</Alert>
                                    
                                    </div>)
                                }

<Button
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    onClick={buySubscription}
                                >
                                   Buy Subscription
                                </Button>
                               
                                
                    </Box>
                </Grid>
                
            </Grid>
            {subscriptionPurchased &&
                                (<div sx={{mt: 5}}>
                                    <Alert severity="success">
                                    <AlertTitle>Subscribtion Purchased</AlertTitle>
                                   Your subscribtion has beeen purchased successfully, click on Connnect Wallet to continue using the portal.</Alert>
                                    
                                    </div>)
                                }
            
        </>
    );
};

export default FirebaseLogin;
