/* eslint-disable prettier/prettier */
import { Navigate } from "react-router-dom";
import { useAuth } from "Context/AuthContext";

export const RequireAuth = ({children}) =>{
    const {isLoggedIn, loggedInUser, connectWallet, connectedAccount} = useAuth();
    if(!connectedAccount){
       
        //return <Navigate to='/login' />
    }
    return children;
}