import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import NonDashboardLayout from 'layout/NonDashboardLayout';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));

const GovernancePage = Loadable(lazy(() => import('views/pages/governance/governance')));
const GovernanceOverviewPage = Loadable(lazy(() => import('views/pages/governance/governance-overview')));

// ==============================|| NonDashboard ROUTING ||============================== //

const NonDashboardRoutes = {
    path: '/',
    element: <NonDashboardLayout />,
    children: [
        {
            path: '/governance',
            element: <GovernancePage />
        },
        {
            path: '/governance-overview',
            element: <GovernanceOverviewPage />
        }
    ]
};

export default NonDashboardRoutes;
