/* eslint-disable prettier/prettier */
import Web3Modal from 'web3modal'
import { ethers } from 'ethers'
import { useState, createContext, useContext, useEffect } from "react";
import { useMoralis } from "react-moralis";
import { web3AuthAddress, hunyCombTokenAddress } from "smartContractConfig.js";
import Web3Auth from '../ABI/Web3Auth.json';

const AuthContext = createContext(null);

//const web3 = new Web3(window.ethereum);
//https://silent-clean-diamond.matic-testnet.discover.quiknode.pro/689d59ee932722e4eb2504e0ddb4f4670c275be8/

const Web3 = require('web3');
const web3 = new Web3(new Web3.providers.HttpProvider('https://silent-clean-diamond.matic-testnet.discover.quiknode.pro/689d59ee932722e4eb2504e0ddb4f4670c275be8/'));


export const AuthProvider = ({children}) =>{

    const [loggedInUser, setLoggedInUser] = useState(null);
    const [connectedAccount, setConnectedAccount] = useState(null);
    const [ERC20TokenBalance, setERC20TokenBalance] = useState(0);
    const [accountBalance, setAccountBalance] = useState(0);
    const [authError, setAuthError] = useState('');

    //const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userInfo')));

    const APP_ID = process.env.REACT_APP_MORALIS_APPLICATION_ID;
    const SERVER_URL = process.env.REACT_APP_MORALIS_SERVER_URL;

    const {Moralis ,authenticate, logout, enableWeb3} = useMoralis();
    Moralis.start({ serverUrl: SERVER_URL, appId: APP_ID });

    
    const [isAuthenticating, setIsAuthenticating] = useState(false);

    const authenticateUser = async(request) =>{
        try {
        const user = await Moralis.User.logIn(request.email, request.password, { usePost: true });
        setLoggedInUser(user.attributes);
        
        return user;
        } catch (error) {
            console.log(error);
            throw error;
        }
        
    }

    const connectWallet = async(provider) =>{
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const balance = await web3.eth.getBalance(accounts[0]);
        const balanceInEther = web3.utils.fromWei(balance, 'ether');
        console.log(balanceInEther);
        const hasAccess = await Web3NFTAuthentication(accounts[0], 4);
        if(hasAccess == false){
            console.log('does not have access');
            setAuthError('You are not authorize to access this portal, kindly buy subscription and try again later!')
            return;
        }
        else{
            setAccountBalance(balanceInEther);
            
            setLoggedInUser(accounts[0]);    
            const erc20Balance = getERC20TokenBalance();
    
            const value = { 'connectedAccount' : accounts[0], 'nativeBalance' : balanceInEther, 'erc20Balance' : erc20Balance}
            localStorage.setItem('loggedInUser', JSON.stringify(value));
            setConnectedAccount(accounts[0]);
            
            //window.location.reload();
            window.location.href="/dashboard/default";
            console.log('connectedAc', connectedAccount);
            // const userSession = JSON.parse(localStorage.getItem('loggedInUser'))
            // console.log(userSession);
        }
        
    }

    const getERC20TokenBalance = async() =>{
        const erc20Abi = [
            {
                "constant": true,
                "inputs": [
                    {
                        "name": "_owner",
                        "type": "address"
                    }
                ],
                "name": "balanceOf",
                "outputs": [
                    {
                        "name": "balance",
                        "type": "uint256"
                    }
                ],
                "payable": false,
                "type": "function"
            }
        ];        
       
         const tokenContract = new web3.eth.Contract(erc20Abi, hunyCombTokenAddress);
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    
        try {
            const tokenDecimal = 18;
            // Get the user's token balance
            const balance = await tokenContract.methods.balanceOf(accounts[0]).call();
            const tokenBalance = balance / (10 ** tokenDecimal); // Calculate token balance based on decimals
            console.log(`User balance: ${tokenBalance}`);
            setERC20TokenBalance(tokenBalance);
            return tokenBalance;
          } catch (err) {
            console.log('Error getting token balance:', err);
          }
        return 0;
    }

    async function Web3NFTAuthentication(account, tokenId){
        try {
            //setLoadingState('loading');        

            console.log('calling....', account, tokenId);
           const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect()
            const provider = new ethers.providers.Web3Provider(connection)
            const signer = provider.getSigner()
      
            //const provider = new ethers.providers.JsonRpcProvider(NODE_URL);
      
            const contract = new ethers.Contract(web3AuthAddress, Web3Auth, signer);
            console.log(contract);
            const data = await contract.hasAccess(account, tokenId);
            //setLoadingState('loaded')
            return data;
            
          } catch (error) {
            console.log('Web3NFTAuthentication ', error);
            setLoadingState('loaded')
            return false;
          }
      }

    // //const connectWallet = async(provider) =>{
    //     const connectWallet1= async (provider) => {
    //         try {
    //           setAuthError(null);
    //           setIsAuthenticating(true);
        
    //           // Enable web3 to get user address and chain
    //           await enableWeb3({ throwOnError: true, provider });
    //           const { account, chainId } = Moralis;
        
    //           if (!account) {
    //             throw new Error('Connecting to chain failed, as no connected account was found');
    //           }
    //           if (!chainId) {
    //             throw new Error('Connecting to chain failed, as no connected chain was found');
    //           }
    //           console.log('account', account);
        
    //           // Get message to sign from the auth api
    //           const { message } = await Moralis.Cloud.run('requestMessage', {
    //             address: account,
    //             chain: parseInt(chainId, 16),
    //             networkType: 'evm',
    //           });
        
    //           // Authenticate and login via parse
    //           await authenticate({
    //             signingMessage: message,
    //             throwOnError: true,
    //           });
    //          // onClose();
    //         } catch (error) {
    //             console.log('AuthError:',error);
    //           setAuthError(error);
    //         } finally {
    //           setIsAuthenticating(false);
    //         }
    //       };
        // await authenticate({ provider: 'metamask',
        //   mobileLinks: [
        //     "rainbow",
        //     "metamask",
        //     "argent",
        //     "trust",
        //     "imtoken",
        //     "coinbase",
        //     "pillar"]
        //     , signingMessage : 'HunyComb need access to your account'})
        //       .then(function (user) {
        //         console.log("logged in user:", user);
        //         localStorage.setItem("connectedUser", JSON.stringify(user.attributes));
        //         setConnectedAccount(user.attributes?.ethAddress);
        //         window.location.reload();
        //         window.location.href="/dashboard/default";
        //       })
        //       .catch(function (error) {
        //         console.log('Error - ',error);
        //       });
      //}

      

   

    const isLoggedIn = async() =>{

        if(window.ethereum && window.ethereum.isConnected()){
            const userSession = JSON.parse(localStorage.getItem('loggedInUser'))
            console.log('userSession', userSession);
            //if(!userSession){
              //  return false;
            //}

            const balance = await web3.eth.getBalance(userSession.connectedAccount);
            const balanceInEther = web3.utils.fromWei(balance, 'ether');
       
            setAccountBalance(balanceInEther);

            getERC20TokenBalance();

            setConnectedAccount(userSession.connectedWallet)
            console.log(userSession);
            return true;
        }else{
            return false;
        }
        
    }

    const signOut = async() =>{
        await logout();
        setLoggedInUser(null);
        setConnectedAccount(null);
        localStorage.clear();
    }
    
    useEffect(() =>{
        //adminSignup();
        
        isLoggedIn()
        getERC20TokenBalance();
      },[])

    return <AuthContext.Provider value={{
        loggedInUser,
        signOut, 
        isLoggedIn, 
        authenticateUser,
        connectWallet,
        connectedAccount,
        ERC20TokenBalance,
        accountBalance,
        authError
    }} >{children}</AuthContext.Provider>
}

export const useAuth = () =>{
    return useContext(AuthContext);
}