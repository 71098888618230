// assets
/* eslint-disable prettier/prettier */
import { IconKey, IconLock } from '@tabler/icons';

// ==============================|| MultiSig MENU ITEMS ||============================== //

const staking = {
    id: 'staking',
    title: 'Staking',
    type: 'group',
    children: [
        {
            id: 'create-staking',
            title: 'Create Staking',
            type: 'item',
            url: '/staking/create-staking',
            icon: IconLock,
            breadcrumbs: false
        },
        {
            id: 'manage-staking',
            title: 'Manage Staking',
            type: 'item',
            url: '/staking/manage-staking',
            icon: IconKey,
            breadcrumbs: false
        }
              
    ]
};
export default staking;
