import { Outlet } from 'react-router-dom';

// project imports
import Customization from '../Customization';

// ==============================|| SPA LAYOUT ||============================== //

const NonDashboardLayout = () => (
    <>
        <Outlet />
        <Customization />
    </>
);

export default NonDashboardLayout;
