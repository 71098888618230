/* eslint-disable prettier/prettier */
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { RequireAuth } from 'utils/RequireAuth';
import ManageDAOPage from 'views/DAOs/manage-dao';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const CreateVestingPage = Loadable(lazy(() => import('views/token-vesting/create-vesting')));
const ManageVestingPage = Loadable(lazy(() => import('views/token-vesting/manage-vesting')));
const ManageWallet = Loadable(lazy(() => import('views/multisig/manage-wallet')));
const ManageFunds = Loadable(lazy(() => import('views/multisig/manage-funds')));
const ManageTransfers = Loadable(lazy(() => import('views/multisig/manage-transfers')));
const ManageWalletOwners = Loadable(lazy(() => import('views/multisig/manage-wallet-owners')));
const ManageTokens = Loadable(lazy(() => import('views/multisig/manage-tokens')));

const GovernancePage = Loadable(lazy(() => import('views/pages/governance/governance')));
const GovernanceOverviewPage = Loadable(lazy(() => import('views/pages/governance/governance-overview')));
const ManageGovernancePage = Loadable(lazy(() => import('views/pages/governance/manage-governance')));
const EligibleVotersPage = Loadable(lazy(() => import('views/pages/governance/eligible-voters')));

const ManageUserPage = Loadable(lazy(() => import('views/dashboard/manage-user')));
const UserProfileSettings = Loadable(lazy(() => import('views/dashboard/user-profile-settings')));

const CreateStakingPage = Loadable(lazy(() => import('views/staking/create-staking')));
const ManageStakingPage = Loadable(lazy(() => import('views/staking/manage-staking')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <RequireAuth><DashboardDefault /></RequireAuth>
            
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <RequireAuth><DashboardDefault /></RequireAuth>
                }
            ]
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'manage-user',
                    element: <RequireAuth><ManageUserPage /></RequireAuth>
                }
            ]
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'profile-settings',
                    element: <RequireAuth><UserProfileSettings /></RequireAuth>
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-typography',
                    element: <UtilsTypography />
                }
            ]
        },
        {
            path: 'multisig',
            children: [
                {
                    path: 'manage-wallet',
                    element: <ManageWallet />
                }
            ]
        },
        {
            path: 'multisig',
            children: [
                {
                    path: 'manage-wallet-owners',
                    element: <ManageWalletOwners />
                }
            ]
        },
        {
            path: 'multisig',
            children: [
                {
                    path: 'manage-funds',
                    element: <ManageFunds />
                }
            ]
        },
        {
            path: 'multisig',
            children: [
                {
                    path: 'manage-tokens',
                    element: <ManageTokens />
                }
            ]
        },
        {
            path: 'multisig',
            children: [
                {
                    path: 'manage-transfers',
                    element: <ManageTransfers />
                }
            ]
        },
        {
            path: 'governance',
            children: [
                {
                    path: 'governance',
                    element: <GovernancePage />
                }
            ]
        },
        {
            path: 'governance',
            children: [
                {
                    path: 'cast-vote',
                    element: <GovernanceOverviewPage />
                }
            ]
        },
        {
            path: 'governance',
            children: [
                {
                    path: 'manage-governance',
                    element: <ManageGovernancePage />
                }
            ]
        },
        {
            path: 'governance',
            children: [
                {
                    path: 'eligible-voters',
                    element: <EligibleVotersPage />
                }
            ]
        },
        {
            path: 'governance',
            children: [
                {
                    path: 'manage-governance',
                    element: <GovernancePage />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-color',
                    element: <UtilsColor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },
        {
            path: 'sample-page',
            element: <SamplePage />
        },
        {
            path: 'create-vesting',
            element: <RequireAuth><CreateVestingPage /></RequireAuth>
        },
        {
            path: 'manage-vesting',
            element: <RequireAuth><ManageVestingPage /></RequireAuth>
        },
        {
            path: 'manage-dao',
            element: <RequireAuth><ManageDAOPage /></RequireAuth>
        },
        {
            path: 'staking',
            children: [
                {
                    path: 'create-staking',
                    element: <RequireAuth><CreateStakingPage /></RequireAuth>
                }
            ]
        },
        {
            path: 'staking',
            children: [
                {
                    path: 'manage-staking',
                    element: <RequireAuth><ManageStakingPage /></RequireAuth>
                }
            ]
        },
    ],
    
};

export default MainRoutes;
