/* eslint-disable prettier/prettier */
// material-ui
import * as React from 'react';
import { Button, Typography, Container, TextareaAutosize, DialogContent, DialogContentText, DialogActions, RadioGroup, Radio } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';

import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, Grid, Stack, useMediaQuery } from '@mui/material';

import AuthWrapper1 from '../pages/authentication/AuthWrapper1';
import AuthCardWrapper from '../pages/authentication/AuthCardWrapper';
import AuthLogin from '../pages/authentication/auth-forms/AuthLogin';
import Logo from 'ui-component/Logo';
import AuthFooter from 'ui-component/cards/AuthFooter';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import InputAdornment from '@mui/material/InputAdornment';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';

import { usePortalContext } from 'Context/PortalContext';
import Loader from 'ui-component/Loader';
import { useSnackbar } from 'notistack';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

// ==============================|| SAMPLE PAGE ||============================== //

function createData(vestingSchedulesId, beneficiary, slicePeriodSeconds, duration, cliff, amountTotal, block_timestamp) {
  return { vestingSchedulesId, beneficiary, start, slicePeriodSeconds, duration, cliff, amountTotal, block_timestamp };
}


const ManageDAOPage = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const { offChainProposals, 
          loadingState, 
          eligibleVoters, 
          withdrawTokens, 
          updateProposalStatus,
          createProposal,
          smartContractError,
          onChainProposals
         } = usePortalContext();

  const [value, setValue] = React.useState(0);
  const [formInput, updateFormInput] = React.useState(
    {
      proposalStatus:'',
      proposalDescription : '',
      proposalCategory: '',
      objectId: ''
    });
    const [open, setOpen] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState(null);
    const { enqueueSnackbar } = useSnackbar();

    const handleClickOpen = (item) => {
      setSelectedRow(item);
      formInput.proposalDescription=item.attributes.description;
      formInput.proposalCategory= item.attributes.category;
      updateFormInput({ ...formInput, objectId: item.id});
      setOpen(true);
      console.log(item);
    };

  const handleClose = () => {
    setOpen(false);
  };
  const handleUpdateProposalSubmit = () =>{
    //
    try {
      if(!formInput.proposalStatus){
        enqueueSnackbar('Please select the status which you want to change', { variant: 'error' });
        return;
      }
      
      if(formInput.proposalStatus ==='Approved'){
        const request = {
          description: formInput.proposalDescription,
          offChainId: formInput.objectId,
          eligibleVoters: eligibleVoters,
          category: formInput.proposalCategory,
          deadline: 60480 //1 week (block timestamp)
      };
        createProposal(request);
        updateProposalStatus(formInput);
        if(smartContractError){
          enqueueSnackbar(smartContractError,{ variant: 'error' });
          return;
        }
      }else{
        updateProposalStatus(formInput);
      }
      
      setOpen(false);
      setSelectedRow(null);
      enqueueSnackbar('Proposal status has been updated!', { variant: 'success' });
    } catch (error) {
     enqueueSnackbar('Something went wrong, please try again later', { variant: 'error' });
    }
    
  }

  const decimals = 1000000000000000000;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const checkReleasableToken = async () => {

  }

  const handleCreateProposal = async () => {
    await withdrawTokens(formInput);
  }

  const secondsToDhms = (seconds) => {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d > 0 ? d + (d == 1 ? " Day, " : " Days, ") : "";
    var hDisplay = h > 0 ? h + (h == 1 ? " Hour, " : " Hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " Min, " : " Mins, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " Sec" : " Secs") : "";
    let final = dDisplay + hDisplay + mDisplay// + sDisplay;
    return final.replace(/,\s*$/, "");
  }

  const secondsToDateTime = (seconds) => {


    const milliseconds = seconds * 1000 // 1575909015000

    const dateObject = new Date(milliseconds)
    return dateObject.toLocaleDateString("en-US") + " " + dateObject.toLocaleTimeString("en-US");
  }

  return (
    <MainCard title="Manage DAOs">
      <Box sx={{ width: '100%' }}>
        {loadingState === 'loading' &&
          <Loader />
        }
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            variant="fullWidth"
            aria-label="Vesting Details"
          >
            <Tab label="Off-chain Proposals" {...a11yProps(0)} />
            <Tab label="Ongoing Proposals" {...a11yProps(1)} />
            <Tab label="Eligible Voters" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Container sx={{ mt: 2, mb: 2 }}>

            <TableContainer>
              <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell align='left'>Proposal</TableCell>
                    <TableCell align='left'>Deadline</TableCell>
                    <TableCell align='left'>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {offChainProposals.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell width="60%" align='left'>
                        {row.attributes.description}
                        <Typography varient="h4" sx={{ color: '#fff' }} >
                          Category: {row.attributes.category}
                          
                        </Typography>
                        <Typography varient="h4" >
                        Status: {row.attributes.status}
                        </Typography>
                        
                      </TableCell>
                      <TableCell width="20%" align='left'>
                        1 Week
                      </TableCell>
                      <TableCell align='left'>
                        <Button color="secondary" size="small" 
                        disabled={row.attributes.status=='Approved'}
                        onClick={()=>handleClickOpen(row)} variant="outlined">Update Status</Button>
                      </TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

          </Container>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Container sx={{ mt: 2, mb: 2 }}>

          <TableContainer>
              <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell align='left'>Proposal</TableCell>
                    <TableCell align='left'>Proposal Info</TableCell>
                    <TableCell align='left'>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {onChainProposals.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell width="60%" align='left'>
                        {row.attributes.description}
                        <Typography varient="h4" sx={{ color: '#fff' }} >
                          Category: {row.attributes.categogry}
                        </Typography>
                        <Button color="success" sx={{mt: 1}} variant="outlined" size="small" >On-going</Button>
                        
                      </TableCell>
                      <TableCell width="20%" align='left'>
                      <Typography varient="h4" sx={{ color: '#fff' }} >
                          Total Voters: {row.attributes.maxVotes}
                        </Typography>
                        <Typography varient="h4" >
                          {row.attributes.proposer}
                        </Typography>
                        <Typography varient="h4" sx={{ color: '#fff' }} >
                          Deadline: {row.attributes.deadline} Blocks
                        </Typography>
                        
                      </TableCell>
                      <TableCell align='left' width="10%">
                        <Button color="secondary" size="small" 
                        disabled={row.attributes.status=='Approved'}
                         variant="outlined">Count Vote</Button>
                      </TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

          </Container>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell >Eligible Voters</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {eligibleVoters.map((row) => (
                    <TableRow
                      key={row}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >

                      <TableCell>{row}</TableCell>
                      

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </TabPanel>
      </Box>

      <Dialog open={open} onClose={handleClose}  >
        <DialogTitle sx={{border: 'solid 1px #ffc107', minWidth:'500px', borderRadius: 0}}>Update Status</DialogTitle>
        <DialogContent sx={{border: 'solid 1px #ffc107'}}>
          <DialogContentText sx={{mt:2}}>
          <b>Proposal:</b> {selectedRow?.attributes.description}
          </DialogContentText>
          
            <RadioGroup sx={{ mb: 2, mt: 2 }}
                    aria-labelledby="radio-buttons-group-beneficiary"
                    defaultValue=""
                    name="radio-buttons-group"
                    onChange={e => updateFormInput({ ...formInput, proposalStatus: e.target.value })}
                >
                    <FormControlLabel size='medium' value="Rejected" control={<Radio size='medium' color="success" />} label="Rejected" />
                    <FormControlLabel size='medium' value="In-review" control={<Radio size='medium' color="success" />} label="In-review" />
                    <FormControlLabel size='medium' value="Approved" control={<Radio size='medium' color="success" />} label="Approved" />
      
                </RadioGroup>
        </DialogContent>
        <DialogActions sx={{border: 'solid 1px #ffc107'}}>
          <Button color="secondary" onClick={handleClose}>Cancel</Button>
          <Button color="secondary"  onClick={handleUpdateProposalSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>

    </MainCard>
  )
};

export default ManageDAOPage;
