/* eslint-disable prettier/prettier */
const DatabaseConst = {
    ON_CHAIN_TABLE_NAME: 'OnChainProposals',
    OFF_CHAIN_TABLE_NAME: 'OffChainProposal',
    VOTES_ON_PROPOSAL : 'VotesOnProposal',
    MULTISIG_FACTORY_WALLETS : 'MultiSigFactory_WalletCreated',
    MULTISIG_WALLET_OWNERS : 'MultiSig_WalletOwners',
    MULTISIG_DEPOSITED_FUNDS: 'MultiSig_DepositedFunds',
    MULTISIG_WITHDRAWN_FUNDS: 'MultiSig_WithdrawnFunds',
    MULTISIG_TRANSFER_REQUEST : 'MultiSig_TransferRequest',
    MULTISIG_TRANSFER_REQUEST_CANCELLED : 'MultiSig_CancelledTransferRequest',
    MULTISIG_TRANSFER_REQUEST_APPROVED : 'MultiSig_ApprovedTranssferRequest',
    MULTISIG_ADDED_TOKENS : 'MultiSig_AddedTokens',
    MULTISIG_TRANSFER_EXECUTED : 'MultiSig_ExecutedTransfers',
    MULTISIG_DEPOSITED_TOKENS : 'MultiSig_DepositedTokens',
    MULTISIG_WITHDRAWN_TOKENS : 'MultiSig_WithdrawnTokens'
};

export default DatabaseConst;