// assets
/* eslint-disable prettier/prettier */
import GroupIcon from '@mui/icons-material/Group';
import GavelIcon from '@mui/icons-material/Gavel';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';

// ==============================|| Governance MENU ITEMS ||============================== //

const Governance = {
    id: 'governance',
    title: 'Governance',
    type: 'group',
    children: [
        {
            id: 'governance',
            title: 'Governance Overview',
            type: 'item',
            url: '/governance/governance',
            icon: AssuredWorkloadIcon,
            breadcrumbs: false
        },
        {
            id: 'manage-governance',
            title: 'Manage Governance',
            type: 'item',
            url: '/governance/manage-governance',
            icon: GavelIcon,
            breadcrumbs: false
        },
        {
            id: 'eligible-voters',
            title: 'Eligible Voters',
            type: 'item',
            url: '/governance/eligible-voters',
            icon: GroupIcon,
            breadcrumbs: false
        }
              
    ]
};
export default Governance;
