// assets
/* eslint-disable prettier/prettier */
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import TokenIcon from '@mui/icons-material/Token';

// ==============================|| MultiSig MENU ITEMS ||============================== //

const multiSig = {
    id: 'multi-sig',
    title: 'Multi-Sig',
    type: 'group',
    children: [
        {
            id: 'manage-wallet',
            title: 'Manage Wallet',
            type: 'item',
            url: '/multisig/manage-wallet',
            icon: AdminPanelSettingsIcon,
            breadcrumbs: false
        },
        {
            id: 'manage-wallet-owners',
            title: 'Manage Wallet Owners',
            type: 'item',
            url: '/multisig/manage-wallet-owners',
            icon: AccountBalanceWalletIcon,
            breadcrumbs: false
        },
        {
            id: 'manage-funds',
            title: 'Manage Funds',
            type: 'item',
            url: '/multisig/manage-funds',
            icon: AttachMoneyIcon,
            breadcrumbs: false
        },
        {
            id: 'manage-tokens',
            title: 'Manage Tokens',
            type: 'item',
            url: '/multisig/manage-tokens',
            icon: TokenIcon,
            breadcrumbs: false
        },
        {
            id: 'manage-transfers',
            title: 'Manage Transfers',
            type: 'item',
            url: '/multisig/manage-transfers',
            icon: SendToMobileIcon,
            breadcrumbs: false
        }
              
    ]
};
export default multiSig;
