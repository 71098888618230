/* eslint-disable prettier/prettier */

import { useState, createContext, useContext, useEffect } from "react";
import { ethers } from 'ethers'
import Web3Modal from 'web3modal'
import axios from 'axios'
import {useMoralis, useMoralisWeb3Api } from "react-moralis";

import { tokenVestingAddress, hunyCombTokenAddress, daoAddress, multiSigAddress, multiSigFactoryAddress, stakingAddress, web3AuthAddress } from "smartContractConfig.js";

import TokenVesting from '../ABI/TokenVesting.json'
import HunyCombToken from '../ABI/HunyCombToken.json'
import Dao from '../ABI/Dao.json';
import MultiSig from '../ABI/MultiSig.json';
import MultiSigFactory from '../ABI/MultiSigFactory.json';
import StakingV1 from '../ABI/StakingV1.json';
import Web3Auth from '../ABI/Web3Auth.json';

import { useMoralisQuery } from "react-moralis";
import proposalConst from "constants/ProposalConst";
import DatabaseConst from "constants/DatabaseConst";

const PortalContext = createContext(null);

const NODE_URL = "https://matic-mumbai.chainstacklabs.com";

export const PortalContextProvider = ({ children }) => {

  const[loadingState, setLoadingState] = useState('')
  const[vestingSchedules, setVestingSchedules] = useState([])
  const[releasedVestings, setReleasedVestings] = useState([])
  const[releasableTokens, setReleasableTokens] = useState(0);
  const[offChainProposals, setOffChainProposals] = useState([])
  const[ERC20TokenBalance, setERC20TokenBalance] = useState(0);
  const[ERC20TokenTransfers, setERC20TokenTransfers] = useState([])
  const[hunyCombTokenHolders, setHunyCombTokenHolders] = useState([])
  const[eligibleVoters, setEligibleVoters] = useState([]);
  const[smartContractError, setSmartContractError] = useState('');
  const[onChainProposals, setOnChainProposals] = useState([])
  const[votesOnProposal, setVotesOnProposal] = useState([])
  const[selectedProposal, setSelectedProposal] = useState([])
  const[multiSigContractBalance, setMultiSigContractBalance] = useState([]);
  const[multiSigWallets, setMultiSigWallets] = useState([])
  const[walletOwners, setWalletOwners] = useState([]);
  const[depositedFunds, setDepositedFunds] = useState([]);
  const[withdrawnFunds, setWithdrawnFunds] = useState([]);
  const[transferRequests, setTransferRequests] = useState([]);
  const[cancelledTransferRequests, setCancelTransferRequests] = useState([]);
  const[approvedTransferRequests, setApproveTransferRequests] = useState([]);
  const[addedERC20Tokens, setAddedERC20Tokens] = useState([]);
  const[depositedERC20Tokens, setDepositedERC20Tokens] = useState([]);
  const[portalUsers, setPortalUsers] = useState([]);
  const[openStakedPositions, setOpenStakedPosition] = useState([]);
  const[closedStakedPositions, setClosedStakedPosition] = useState([]);
  const [accountBalance, setAccountBalance] = useState('')
  const [nativeBalance, setNativeBalance] = useState('');
  const [earnedReward, setEarnedReward] = useState('');
  const [subscriptionPurchased, setSubscriptionPurchased] = useState('');
  

  const APP_ID = process.env.REACT_APP_MORALIS_APPLICATION_ID;
  const SERVER_URL = process.env.REACT_APP_MORALIS_SERVER_URL;
  const ENV = process.env.REACT_APP_ENVIRONMENT;
  const MASTER_KEY = process.env.REACT_APP_MORALIS_MASTER_KEY;

  const DECIMALS = 1000000000000000000;
 
  const Web3Api = useMoralisWeb3Api();
  const { Moralis,} = useMoralis();

  Moralis.start({ serverUrl: SERVER_URL, appId: APP_ID, masterKey: 'S22hxLCbVZAwLI9RBik1wDzfpBryQlWilGJD1b5Z' });

  const CHAIN = ENV;
  const USER_ADDRESS = Moralis.User.current()?.get("ethAddress");

  let moralisOptions = {
    chain: CHAIN,
    address: USER_ADDRESS
  };

  const fetchERC20TokenBalances = async () => {
    // const addr = Moralis.User.current()?.get("ethAddress");
    // console.log(addr);
    // if(!addr){
    //     return;
    // }
    // const balances = await Web3Api.account.getTokenBalances(moralisOptions);
    // const tokens = parseFloat(balances.find(x => x.token_address == hunyCombTokenAddress).balance / DECIMALS);
    // setERC20TokenBalance(tokens);
  };

  const fetchWalletBalance = async () => {
    // get mainnet native balance for the current user
    const nativeBalance = await Web3Api.account.getNativeBalance();
    //console.log('native balance',nativeBalance);

    const nativeBal = parseFloat(ethers.utils.formatUnits(nativeBalance.balance.toString(), 'ether')).toFixed(9);
    setNativeBalance(nativeBal);
    //console.log('nativeBal',nativeBal);

    const ethBalance = await Web3Api.account.getNativeBalance(moralisOptions);
    const balance = parseFloat(ethers.utils.formatUnits(ethBalance.balance.toString(), 'ether')).toFixed(9);
    setAccountBalance(balance);
    //console.log('balance',balance);
  };

  const fetchERC20TokenTransfers = async () => {
    const params ={
        chain : ENV,
        address : '0x2CC0F54Da3eAF7A6a24E8178D9CE5F17116B9D12'
    };
    const transfers = await Web3Api.account.getTokenTransfers(params);
    setERC20TokenTransfers(transfers);
    console.log('transfers', transfers);
  };

  async function getAllVestings(){
    const query = new Moralis.Query('VestingSchedule');
    setLoadingState('loading');
    //query.equalTo("username", userName)
    try {
        query.find().then(res =>{
            if(res){
                console.log(res);
                setVestingSchedules(res);
            }
            
        })
        setLoadingState('loaded');
    } catch (error) {
        setLoadingState('loaded');
        console.log(error);
        throw error;

    }
    
  }

 async function getAllPortalUsers(){
//     setLoadingState('loading');
//     await fetch('https://p502nuj77yta.usemoralis.com:2053/server/classes/_User', {
// method: 'GET',
// headers: {
//    'Content-type': 'application/json; charset=UTF-8',
//    'X-Parse-Application-Id':'wmRDL6T52HKG2KABtvzkz28JL6MRbv0Rh8bEkbRM',
//    'X-Parse-Master-Key':'S22hxLCbVZAwLI9RBik1wDzfpBryQlWilGJD1b5Z'
// },
// })
// .then((response) => response.json())
// .then((data) => {
//    const users = data.results.filter(x => x.username != 'coreservices')
//    console.log(users);
//    setPortalUsers(users);
//    setLoadingState('loaded');
// })
// .catch((err) => {
//    console.log(err.message);
//    setLoadingState('loaded');
// });
    
  }

  const createOffChainProposal = async(req) =>{
    setLoadingState('loading');
    const DB = Moralis.Object.extend(proposalConst.CLASS_NAME);
    const proposal = new DB();
    try {
     proposal.save({
        'category': req.proposalCategory,
        'description': req.proposalDescription,
        'proposedBy': USER_ADDRESS,
        'eligibleVoters': eligibleVoters,
        'deadline': req.proposalDeadline,
        'status': 'Draft'

    }).then((res) =>{
        setLoadingState('loaded');
        getAllOffChainProposals();
    }, (error) =>{
        console.log('Error', error);
        setLoadingState('loaded');
        throw error;
    });
    } catch (error) {
        setLoadingState('loaded');
        throw("Error: " + error.code + " " + error.message);
    }
}

const updateUserRole = async(req) =>{
    setLoadingState('loading');
    console.log(req);
    const query = new Moralis.Query(Moralis.User);
          query.equalTo("objectId", req.objectId);
          query.find().then(res =>{
              if(res){
                  const user = res[0];
                  user.set('role',req.role);
                  
                    user.save();
                    getAllPortalUsers();
                  setLoadingState('loaded');
              }
              
          }, (error) =>{
            console.log('Error', error);
            setLoadingState('loaded');
            throw error;
        });
    
}

const updateUserProfile = async(req) =>{
    setLoadingState('loading');
    const query = new Moralis.Query(Moralis.User);
          
          query.find().then(res =>{
              if(res){
                console.log(res);
                  const user = res[0];
                  user.set('displayUserName',req.userFullName);
                  user.set('email',req.userEmail);
                  user.set('phone',req.userPhone);
                  user.save();
                  
                  setLoadingState('loaded');
              }
              
          }, (error) =>{
            console.log('Error', error);
            setLoadingState('loaded');
            throw error;
        });
    
}

const updateProposalStatus = async(req) =>{
    setLoadingState('loading');
    const query = new Moralis.Query(proposalConst.CLASS_NAME);
          query.equalTo("objectId", req.objectId)
          query.find().then(res =>{
              if(res){
                  const proposal = res[0];
                  proposal.set(proposalConst.STATUS,req.proposalStatus);
                  
                    getAllOffChainProposals();
                    proposal.save();
                  
                  setLoadingState('loaded');
              }
              
          }, (error) =>{
            console.log('Error', error);
            setLoadingState('loaded');
            throw error;
        });
    
}

async function getHunyCombTokenHolders(){
    setLoadingState('loading');
    const query = new Moralis.Query('HunyCombTransfers');
    try {
        query.find().then(res =>{
            if(res){
                console.log(res);
                let arr = [];
                
                for(let i=0; i <res.length; i++){
                    if(res[i].attributes.to != tokenVestingAddress){
                        arr.push(res[i].attributes.to);//, 'transfers': (res[i].attributes.value / DECIMALS)});
                    }
                }
               // setHunyCombTokenHolders();
                setEligibleVoters([...new Set(arr)]);
            }
            
        });
        setLoadingState('loaded');
    } catch (error) {
        console.log(error);
        setLoadingState('loaded');
        throw error;
    }
    
  }

async function getAllOffChainProposals(){
    setLoadingState('loading');
    const query = new Moralis.Query(DatabaseConst.OFF_CHAIN_TABLE_NAME);
    try {
        query.find().then(res =>{
            if(res){
                console.log(res);
                setOffChainProposals(res);
            }         
        });
        setLoadingState('loaded');
    } catch (error) {
        console.log(error);
        setLoadingState('loaded');
        throw error;
    }
    
  }
  async function getAllOnChainProposals(){
    setLoadingState('loading');
    const query = new Moralis.Query(DatabaseConst.ON_CHAIN_TABLE_NAME);
    try {
        query.find().then(res =>{
            if(res){
                console.log(res);
                setOnChainProposals(res);
            }         
        });
        setLoadingState('loaded');
    } catch (error) {
        console.log(error);
        setLoadingState('loaded');
        throw error;
    }
    
  }
  async function getVotesOnProposal(){
    setLoadingState('loading');
    const query = new Moralis.Query(DatabaseConst.VOTES_ON_PROPOSAL);
    try {
        query.find().then(res =>{
            if(res){
                console.log(res);
                setVotesOnProposal(res);
            }         
        });
        setLoadingState('loaded');
    } catch (error) {
        console.log(error);
        setLoadingState('loaded');
        throw error;
    }
    
  }

  async function getOnChainProposalById(offChainId){
    setLoadingState('loading');
   
    const query = new Moralis.Query(DatabaseConst.ON_CHAIN_TABLE_NAME);
    query.equalTo("offChainId", offChainId)
    try {
        query.find().then(res =>{
            if(res){
                console.log('getOnChainProposalById',res[0]);
                //offChainId = res[0].attributes.uid;
                setSelectedProposal(res[0]);
            }         
        });
        setLoadingState('loaded');
    } catch (error) {
        console.log(error);
        setLoadingState('loaded');
        throw error;
    }
    
  }

  async function getAllReleasedVestings(){
    setLoadingState('loading');
    const query = new Moralis.Query('ReleasedVesting');
    //query.equalTo("username", userName)
    try {
        query.find().then(res =>{
            if(res){
                console.log(res);
                setReleasedVestings(res);
                setLoadingState('loaded');
            }
            
        })
    } catch (error) {
        console.log(error);
        setLoadingState('loaded');
        throw error;
    }
    
  }

  async function getAllMultiSigWallets(){
    setLoadingState('loading');
    const query = new Moralis.Query(DatabaseConst.MULTISIG_FACTORY_WALLETS);
    try {
        query.find().then(res =>{
            if(res){
                console.log(res);
                setMultiSigWallets(res);
            }         
        });
        setLoadingState('loaded');
    } catch (error) {
        console.log(error);
        setLoadingState('loaded');
        throw error;
    }
    
  }

  async function getAllWalletOwners(){
    setLoadingState('loading');
    const query = new Moralis.Query(DatabaseConst.MULTISIG_WALLET_OWNERS);
    try {
        query.find().then(res =>{
            if(res){
                console.log(res);
                setWalletOwners(res);
            }         
        });
        setLoadingState('loaded');
    } catch (error) {
        console.log(error);
        setLoadingState('loaded');
        throw error;
    }
    
  }

  async function removeWalletOwnerFromDB(ownerAddress){
    setLoadingState('loading');
    const query = new Moralis.Query(DatabaseConst.MULTISIG_WALLET_OWNERS);
    query.equalTo("ownerAdded", ownerAddress);
    try {
        query.find().then(res =>{
            if(res){
                const walletOwner = res[0];
                walletOwner.destroy();
                
                wallet.save();
            }         
        });
        setLoadingState('loaded');
    } catch (error) {
        console.log(error);
        setLoadingState('loaded');
        throw error;
    }
    
  }

  async function getDepositedFunds(){
    setLoadingState('loading');
    const query = new Moralis.Query(DatabaseConst.MULTISIG_DEPOSITED_FUNDS);
    try {
        query.find().then(res =>{
            if(res){
                setDepositedFunds(res);
            }         
        });
        setLoadingState('loaded');
    } catch (error) {
        console.log(error);
        setLoadingState('loaded');
        throw error;
    }
    
  }

  async function getWithdrawnFunds(){
    setLoadingState('loading');
    const query = new Moralis.Query(DatabaseConst.MULTISIG_WITHDRAWN_FUNDS);
    try {
        query.find().then(res =>{
            if(res){
                console.log(res);
                setWithdrawnFunds(res);
            }         
        });
        setLoadingState('loaded');
    } catch (error) {
        console.log(error);
        setLoadingState('loaded');
        throw error;
    }
    
  }

  async function getAllTransferRequests(){
    setLoadingState('loading');
    const query = new Moralis.Query(DatabaseConst.MULTISIG_TRANSFER_REQUEST);
    try {
        query.find().then(res =>{
            if(res){
                setTransferRequests(res);
            }         
        });
        setLoadingState('loaded');
    } catch (error) {
        console.log(error);
        setLoadingState('loaded');
        throw error;
    }
    
  }

  async function getCancelledTransferRequests(){
    setLoadingState('loading');
    const query = new Moralis.Query(DatabaseConst.MULTISIG_TRANSFER_REQUEST_CANCELLED);
    try {
        query.find().then(res =>{
            if(res){
                setCancelTransferRequests(res);
            }         
        });
        setLoadingState('loaded');
    } catch (error) {
        console.log(error);
        setLoadingState('loaded');
        throw error;
    }
    
  }

  async function getApprovedTransferRequests(){
    setLoadingState('loading');
    const query = new Moralis.Query(DatabaseConst.MULTISIG_TRANSFER_REQUEST_APPROVED);
    try {
        query.find().then(res =>{
            if(res){
                setApproveTransferRequests(res);
            }         
        });
        setLoadingState('loaded');
    } catch (error) {
        console.log(error);
        setLoadingState('loaded');
        throw error;
    }
    
  }

  async function getAddedERC20Tokens(){
    setLoadingState('loading');
    const query = new Moralis.Query(DatabaseConst.MULTISIG_ADDED_TOKENS);
    try {
        query.find().then(res =>{
            if(res){
                setAddedERC20Tokens(res);
            }         
        });
        setLoadingState('loaded');
    } catch (error) {
        console.log(error);
        setLoadingState('loaded');
        throw error;
    }
    
  }


  //////////// ONCHAIN CALL ///////////////

  async function getReleasableTokens(vestingId){
    try {
        setLoadingState('loading');
       const web3Modal = new Web3Modal();
        const connection = await web3Modal.connect()
        const provider = new ethers.providers.Web3Provider(connection)
        const signer = provider.getSigner()
  
        //const provider = new ethers.providers.JsonRpcProvider(NODE_URL);
  
        const contract = new ethers.Contract(tokenVestingAddress, TokenVesting.abi, signer);
        const data = await contract.computeReleasableAmount(vestingId);

        setReleasableTokens(data.toString());
        
        setLoadingState('loaded')
        
      } catch (error) {
        console.log(error);
        setLoadingState('loaded')
      }
  }

  async function withdrawTokens(request){
    try {
        setLoadingState('loading');
       const web3Modal = new Web3Modal();
        const connection = await web3Modal.connect()
        const provider = new ethers.providers.Web3Provider(connection)
        const signer = provider.getSigner()
  
        //const provider = new ethers.providers.JsonRpcProvider(NODE_URL);
        const amountFormatted = ethers.utils.parseUnits(request.amount.toString(), 18);
        const id = request.tokenVestingId;
        
        const contract = new ethers.Contract(tokenVestingAddress, TokenVesting.abi, signer);
        const data = await contract.release(id, amountFormatted);

        console.log(data.toString());
        //setReleasableTokens(data.toString());
        console.log('success');
        setLoadingState('loaded')
        getAllReleasedVestings();
        
      } catch (error) {
        console.log(error);
        setLoadingState('loaded')
      }
  }

  async function createVestingSchedule(request) {
    try {
    setLoadingState('loading');
    console.log('calling....')
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);

      const signer = provider.getSigner();
      const contract = new ethers.Contract(tokenVestingAddress, TokenVesting.abi, signer);

        const startOn = 0;//Math.floor(Date.now() / 1000);
        const amountFormatted = ethers.utils.parseUnits(request.lockAmount.toString(), 18);
        console.log('request', request, 'amount', amountFormatted);
        const transaction = await contract.createVestingSchedule(
        ethers.utils.getAddress(request.beneficiaryAddress),
        startOn,
        request.cliffMonth,
        request.lockDuration,
        request.everyLockIn,
        true,
        amountFormatted//request.lockAmount
        )

      await transaction.wait();
      setLoadingState('loaded');

      console.log('success');
      getAllVestings();

    } catch (error) {
      console.log(error);
       setLoadingState('loaded')
       throw error;
    }
  }

  async function createProposal(request) {
    try {
    setLoadingState('loading');
    setSmartContractError('');
    console.log(request);
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);

      const signer = provider.getSigner();
      const contract = new ethers.Contract(daoAddress, Dao.abi, signer);
        
        const transaction = await contract.createProposal(
        request.description,
        request.offChainId,
        request.eligibleVoters,
        request.deadline,
        request.category
        )

      await transaction.wait();
      setLoadingState('loaded');

      console.log('success');
      getAllOnChainProposals();

    } catch (error) {
      console.log(error);
      setSmartContractError(error.data.message);
       setLoadingState('loaded')
       throw error;
    }
  }

  async function castVoteOnProposal(request) {
    try {
        
      setLoadingState('loading');
      setSmartContractError('');
      console.log(request);
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);

      const signer = provider.getSigner();
      const contract = new ethers.Contract(daoAddress, Dao.abi, signer);
      //const transaction = await contract.voteOnProposal(1,false);
      const transaction = await contract.voteOnProposal(request.id,request.voteFor)

      await transaction.wait();
      setLoadingState('loaded');

      console.log('success');
      getAllOnChainProposals();
      getVotesOnProposal();

    } catch (error) {
      console.log(error);
      setSmartContractError(error.data.message);
       setLoadingState('loaded')
       throw error;
    }
  }

  // ***************** Multi-Sig **********************

async function createWallet() {
    try {
    setLoadingState('loading');
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);

      const signer = provider.getSigner();
      const contract = new ethers.Contract(multiSigFactoryAddress, MultiSigFactory.abi, signer);
        
      const transaction = await contract.createWallet();

      await transaction.wait();
      setLoadingState('loaded');
      
      getAllMultiSigWallets();

    } catch (error) {
       console.log(error);
       setLoadingState('loaded')
       throw error;
    }
  }

  async function addWalletOwner(req) {
    try {
    setLoadingState('loading');
    
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);

      const signer = provider.getSigner();
      const contract = new ethers.Contract(multiSigAddress, MultiSig.abi, signer);
        
      const transaction = await contract.addWalletOwner(req.ownerAddress, multiSigFactoryAddress, multiSigAddress);

      await transaction.wait();
      setLoadingState('loaded');
      
      getAllWalletOwners();

    } catch (error) {
       console.log(error);
       setLoadingState('loaded')
       throw error;
    }
  }

  async function removeWalletOwner(req) {
    try {
      setLoadingState('loading');
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);

      const signer = provider.getSigner();
      const contract = new ethers.Contract(multiSigAddress, MultiSig.abi, signer);
        
      const transaction = await contract.removeWalletOwner(req.ownerAddress, multiSigFactoryAddress, multiSigAddress);

      await transaction.wait();
      setLoadingState('loaded');
      
      removeWalletOwnerFromDB(req.ownerAddress);
      getAllWalletOwners();

    } catch (error) {
       console.log(error);
       setLoadingState('loaded')
       throw error;
    }
  }

  async function depositFundToWallet(req) {
    try {
    setLoadingState('loading');
    
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);

      const signer = provider.getSigner();
      const contract = new ethers.Contract(multiSigAddress, MultiSig.abi, signer);

      const amountFormatted = ethers.utils.parseUnits(req.depositAmount.toString(), 18);
        
      const transaction = await contract.deposit("MATIC",1,{value : amountFormatted});

      await transaction.wait();
      setLoadingState('loaded');
      
      getDepositedFunds();
      getMultiSigContractBalance();

    } catch (error) {
       console.log(error);
       setLoadingState('loaded')
       throw error;
    }
  }

  async function withdrawFundFromWallet(req) {
    try {
    setLoadingState('loading');
    
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);

      const signer = provider.getSigner();
      const contract = new ethers.Contract(multiSigAddress, MultiSig.abi, signer);

      const amountFormatted = ethers.utils.parseUnits(req.withdrawAmount.toString(), 18);
        
      const transaction = await contract.withdraw("MATIC", amountFormatted);

      await transaction.wait();
      setLoadingState('loaded');
      
      getWithdrawnFunds();
      getMultiSigContractBalance();

    } catch (error) {
       console.log(error);
       setLoadingState('loaded')
       throw error;
    }
  }

  async function getMultiSigContractBalance() {
    try {
    setLoadingState('loading');
    
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);

      const signer = provider.getSigner();
      const contract = new ethers.Contract(multiSigAddress, MultiSig.abi, signer);
        
      const data = await contract.getContractBalance();

      setMultiSigContractBalance(data.toString());

      setLoadingState('loaded');
      
      getWithdrawnFunds();

    } catch (error) {
       console.log(error);
       setLoadingState('loaded')
       throw error;
    }
  }

  async function addFundToMultisigWallet(req) {
    try {
    setLoadingState('loading');
    
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);

      const signer = provider.getSigner();
      const contract = new ethers.Contract(multiSigAddress, MultiSig.abi, signer);
        
      const transaction = await contract.addToken(req.tokenSymbol, req.tokenAddress);

      await transaction.wait();
      setLoadingState('loaded');
      
     // getAllWalletOwners();

    } catch (error) {
       console.log(error);
       setLoadingState('loaded')
       throw error;
    }
  }

  async function createTransferRequest(req) {
    try {
    setLoadingState('loading');
       
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);

      const signer = provider.getSigner();
      const contract = new ethers.Contract(multiSigAddress, MultiSig.abi, signer);

      const amountFormatted = ethers.utils.parseUnits(req.transferAmount.toString(), 18);
        
      const transaction = await contract.createTrnasferRequest(req.ticker,req.recipientAddress, amountFormatted);

      await transaction.wait();
      setLoadingState('loaded');
      
      getAllTransferRequests();

    } catch (error) {
       console.log(error);
       setLoadingState('loaded')
       throw error;
    }
  }

  async function approveTransferRequest(req) {
    try {
    setLoadingState('loading');
       
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);

      const signer = provider.getSigner();
      const contract = new ethers.Contract(multiSigAddress, MultiSig.abi, signer);
        
      const transaction = await contract.approveTransfer(req.transferId,"MATIC");

      await transaction.wait();
      setLoadingState('loaded');

      getApprovedTransferRequests();

    } catch (error) {
       console.log(error);
       setLoadingState('loaded')
       throw error;
    }
  }

  async function cancelTransferRequest(req) {
    try {
    setLoadingState('loading');
       
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);

      const signer = provider.getSigner();
      const contract = new ethers.Contract(multiSigAddress, MultiSig.abi, signer);
        
      const transaction = await contract.cancelTransferRequest(req.transferId);

      await transaction.wait();
      setLoadingState('loaded');
      
      getCancelledTransferRequests();

    } catch (error) {
       console.log(error);
       setLoadingState('loaded')
       throw error;
    }
  }

  async function createStaking(tokenQuantity) {
    try {
      setLoadingState('loading');
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);

      const signer = provider.getSigner();
      const contract = new ethers.Contract(stakingAddress, StakingV1.abi, signer);

      const transaction = await contract.stake(tokenQuantity);

      await transaction.wait();
      getAllStakedPositions(true);
        //getAllStakedPositions(false);
      setLoadingState('loaded');

    } catch (error) {
       console.log(error);
       setLoadingState('loaded')
       throw error;
    }
  }

  async function withdrawStaking(req) {
    try {
      setLoadingState('loading');
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);

      const signer = provider.getSigner();
      const contract = new ethers.Contract(stakingAddress, StakingV1.abi, signer);

      const transaction = await contract.withdraw(req.tokenQuantity, req.positionId);

      await transaction.wait();
      getAllStakedPositions(true);
        getAllStakedPositions(false);
      setLoadingState('loaded');

    } catch (error) {
       console.log(error);
       setLoadingState('loaded')
       throw error;
    }
  }

  async function claimStakingReward() {
    try {
      setLoadingState('loading');
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);

      const signer = provider.getSigner();
      const contract = new ethers.Contract(stakingAddress, StakingV1.abi, signer);

      const transaction = await contract.claimReward();

      await transaction.wait();
      setLoadingState('loaded');

    } catch (error) {
       console.log(error);
       setLoadingState('loaded')
       throw error;
    }
  }

  async function getAllStakedPositions(openStatus) {
    try {
      setLoadingState('loading');
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);

      const signer = provider.getSigner();
      const contract = new ethers.Contract(stakingAddress, StakingV1.abi, signer);
        
      const data = await contract.getAllStakingByPositions(openStatus);

      console.log(data);

      let items = await Promise.all(data.map(async i => {
        
        let item = {
          positionId: i.positionId.toNumber(),
          walletAddress: i.walletAddress,
          createdOn: i.createdOn,
          tokenQuantity : i.tokenQuantity.toNumber(),
          open : i.open
        }
        return item
      }))

      items = items.filter(x => x.positionId > 0);
      setLoadingState('loaded');
      if(openStatus)
      setOpenStakedPosition(items);
      else if(!openStatus)
      setClosedStakedPosition(items);

    } catch (error) {
       console.log(error);
       setLoadingState('loaded')
       throw error;
    }
  }

  async function getEarnedReward(userWallet) {
    try {
      setLoadingState('loading');
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);

      const signer = provider.getSigner();
      const contract = new ethers.Contract(stakingAddress, StakingV1.abi, signer);
        
      const data = await contract.earned(userWallet);

      console.log(data.toString());
      setEarnedReward(data.toNumber());
      
      setLoadingState('loaded');

    } catch (error) {
       console.log(error);
       setLoadingState('loaded')
       throw error;
    }
  }

  async function buySubscription() {
    try {
    setLoadingState('loading');
       
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);

      const signer = provider.getSigner();
      const contract = new ethers.Contract(web3AuthAddress, Web3Auth, signer);

      // const transaction = await contract.buyToken(5, {
      //   value: ethers.utils.parseEther("100"), // replace with token price in ether
      // });

     // await transaction.wait();
        
      const transaction = await contract.buyToken("4", {value : 100 });

      await transaction.wait();
      setLoadingState('loaded');
      setSubscriptionPurchased('Your subscribtion has been purchased! ')
    

    } catch (error) {
       console.log(error);
       setLoadingState('loaded')
       throw error;
    }
  }


  //*************************************************** */


  useEffect(() => {
    getAllVestings();
    getAllReleasedVestings();
    getAllOffChainProposals();
    //getReleasableTokens();
    fetchERC20TokenBalances();
    fetchERC20TokenTransfers();
    getHunyCombTokenHolders();
    getAllOnChainProposals();
    getVotesOnProposal();
    getMultiSigContractBalance();
    getAllMultiSigWallets();
    getAllWalletOwners()
    getDepositedFunds();
    getWithdrawnFunds();
    getAllTransferRequests();
    getApprovedTransferRequests();
    getCancelledTransferRequests();
    getAddedERC20Tokens();
    getAllPortalUsers();
    getAllStakedPositions(true);
    getAllStakedPositions(false);
    fetchWalletBalance();
  }, [])

  return <PortalContext.Provider value={{
    createVestingSchedule,
    loadingState,
    vestingSchedules,
    getAllVestings,
    getAllReleasedVestings,
    releasedVestings,
    getReleasableTokens,
    releasableTokens,
    withdrawTokens,
    createOffChainProposal,
    getAllOffChainProposals,
    offChainProposals,
    fetchERC20TokenBalances,
    ERC20TokenBalance,
    eligibleVoters,
    updateProposalStatus,
    createProposal,
    smartContractError,
    onChainProposals,
    castVoteOnProposal,
    votesOnProposal,
    getVotesOnProposal,
    getOnChainProposalById,
    selectedProposal,
    createWallet,
    multiSigWallets,
    addWalletOwner,
    removeWalletOwner,
    walletOwners,
    depositFundToWallet,
    depositedFunds,
    withdrawFundFromWallet,
    withdrawnFunds,
    multiSigContractBalance,
    addFundToMultisigWallet,
    createTransferRequest,
    transferRequests,
    approveTransferRequest,
    approvedTransferRequests,
    cancelTransferRequest,
    cancelledTransferRequests,
    addedERC20Tokens,
    portalUsers,
    updateUserRole,
    updateUserProfile,
    openStakedPositions,
    closedStakedPositions,
    createStaking,
    accountBalance,
    nativeBalance,
    withdrawStaking,
    getEarnedReward,
    earnedReward,
    claimStakingReward,
    buySubscription,
    subscriptionPurchased
  }} >{children}</PortalContext.Provider>
}

export const usePortalContext = () => {
  return useContext(PortalContext);
}