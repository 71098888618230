/* eslint-disable prettier/prettier */
const proposalConst = {
    CLASS_NAME: 'OffChainProposal',
    ID: 'id',
    CATEGORY: 'category',
    DESCRIPTION: 'description',
    PROPPOSED_BY: 'proposedBy',
    DEADLINE: 'deadline',
    ELIGIBLE_VOTERS: 'eligibleVoters',
    APPROVED_BY: 'approvedBy',
    STATUS: 'status'
};

export default proposalConst;