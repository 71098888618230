// assets
import { IconDashboard, IconKey, IconLock } from '@tabler/icons';
import BusinessIcon from '@mui/icons-material/Business';
// constant
const icons = { IconDashboard, IconKey, IconLock, BusinessIcon };
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard/default',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'manage-user',
            title: 'Manage Users',
            type: 'item',
            url: '/dashboard/manage-user',
            icon: ManageAccountsIcon,
            breadcrumbs: false
        },
        // {
        //     id: 'create-vesting',
        //     title: 'Create Vesting',
        //     type: 'item',
        //     url: '/create-vesting',
        //     icon: icons.IconLock,
        //     breadcrumbs: true
        // },
        // {
        //     id: 'track-vesting',
        //     title: 'Manage Vesting',
        //     type: 'item',
        //     url: '/manage-vesting',
        //     icon: icons.IconKey,
        //     breadcrumbs: true
        // },
        // {
        //     id: 'daos',
        //     title: 'DAOs',
        //     type: 'item',
        //     url: '/manage-dao',
        //     icon: icons.BusinessIcon,
        //     breadcrumbs: true
        // },
        // {
        //     id: 'staking',
        //     title: 'Staking',
        //     type: 'item',
        //     url: '/dashboard/default2',
        //     icon: icons.IconDashboard,
        //     breadcrumbs: true
        // }
    ]
};

export default dashboard;
